import React from "react"
import Layout from "../components/layout"
// import Image from '../components/image';

import { graphql } from "gatsby"

import Hero from "../components/Hero"

import SocialProof from "../components/SocialProof"
import Banner from "../components/Banner"
import Features from "../components/Features"
import Testimonial from "../components/Testimonial"
import App from "../components/App"
import Phone from "../images/phone_se.png"

const Post = ({ data }) => (
  <Layout logo={data.post.logo} name={data.post.name}>
    <Hero token={data.post.token} />
    <SocialProof recommendData="96%" gradeData="4.8" patientData="30 000" />
    <Banner />
    <Features />
    <Testimonial />
    <App Phone={Phone} />
  </Layout>
)

export const query = graphql`
  query PostQuerySV($id: String!) {
    post(id: { eq: $id }) {
      id
      name
      logo
      token
      language
    }
  }
`

export default Post
